.step-background {
  width: 42px;
  height: 50px;
  background-image: url('../../../public/img/stepBlue.png');
  background-repeat: no-repeat;
  background-position: center;
}

.step-hr {
  width: 100%;
  border: 1px solid #54a3f5;
  margin-top: 36px;
}
