.filter-creators {
  animation-name: animationcreators;
  animation-duration: 1s;
  animation-timing-function: ease;
  visibility: visible !important;
  overflow: hidden;
}

@keyframes animationcreators {
  from {
    opacity: -0.3;
  }

  to {
    opacity: 1;
  }
}
