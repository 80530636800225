@import 'colors';
@import 'rem';
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

$brand-color: #54a3f5;

@font-face {
  font-family: gothamlight;
  src: url('../public/Fonts/gothamlight-webfont.woff2') format('woff2'),
    url('../public/Fonts/gothamlight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: gothamlight, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.brand-color {
  background-color: $brand-color;
}

.brand-color-text {
  color: $brand-color;
}

.tooltip {
  background-color: #54a3f5 !important;
  border-radius: 20px !important;
  font-size: large !important;

  &.place-top {
    &::after {
      border-top-color: #54a3f5 !important;
    }
  }
}
/* stylelint-disable */
.react-datepicker__day--selected {
  background-color: #54a3f5 !important;
}
/* stylelint-enable */
.react-datepicker {
  box-shadow: rgba(0 0 0 / 35%) 0 5px 15px;
}

/* stylelint-disable */
.react-datepicker__triangle {
  transform: translate(127px, 0) !important;
}
/* stylelint-enable */

.slick-dots li button::before {
  font-size: 20px !important;
}

.slick-prev,
.slick-next {
  width: 28px !important;
  height: 28px !important;
  border: 1px solid #8080803b !important;
  background: #8c8c8c5c !important;
  border-radius: 30px !important;
  padding-top: 2px !important;
}

.slick-next {
  padding-right: 1px !important;
}

.slick-prev {
  padding-left: 1px !important;
}
