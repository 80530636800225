.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

#style-1::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgb(206 206 206 / 30%);
  background-color: rgb(216 216 216 / 100%);
}

.scrollbar {
  overflow-y: scroll;

  // margin-left: 22px;
  // float: left;
  // margin-bottom: 25px;
}

textarea::placeholder {
  display: inline;
  width: 100%;
}
