.test-background {
  background-image: url('../../../public/img/dev.png');
  background-position: center;
}

.mandatory-asterisk::after {
  content: ' *';
  color: #e32;
  position: absolute;
  margin: 0 0 0 -25px;
  font-size: xx-large;
  padding: 5px 0 0;
}

.mandatory-required-text::after {
  content: ' Required field';
  color: #e32;
  position: absolute;
  margin: 5px 0 0 -125px;
  font-size: medium;
  padding: 5px 0 0;
}
