.arrow {
  border: solid rgb(255 255 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  width: 15px;
  height: 15px;
}

.arrow-up {
  transform: rotate(-135deg);
}

.animation-menu-toggle-photographer {
  animation-name: animationphotographer;
  animation-duration: 1s;
  animation-timing-function: ease;
  visibility: visible !important;
  overflow: hidden;
}

@keyframes animationphotographer {
  from {
    height: 0;
    opacity: -0.3;
  }

  to {
    height: 305px;
    opacity: 1;
  }
}

.animation-menu-toggle-photographer-close {
  animation-name: animationphotographerclose;
  animation-duration: 1s;
  background-color: red;
  animation-timing-function: ease;
  visibility: visible !important;
  overflow: hidden;
}

@keyframes animationphotographerclose {
  from {
    height: 305px;
    opacity: 1;
  }

  to {
    height: 0;
    opacity: -0.3;
  }
}

.animation-menu-toggle-brand {
  animation-name: animationbrand;
  animation-duration: 1s;
  animation-timing-function: ease;
  visibility: visible !important;
  overflow: hidden;
}

@keyframes animationbrand {
  from {
    height: 0;
    opacity: -0.3;
  }

  to {
    height: 450px;
    opacity: 1;
  }
}

.animation-menu-toggle-none {
  animation-name: animationnone;
  animation-duration: 1s;
  animation-timing-function: ease;
  visibility: visible !important;
  overflow: hidden;
}

@keyframes animationnone {
  from {
    height: 0;
    opacity: -0.3;
  }

  to {
    height: 73px;
    opacity: 1;
  }
}
