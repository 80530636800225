.imessage {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.5rem;
}

.imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 400px;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: '';
  height: 1rem;
  position: absolute;
}

.chat-contacts {
  max-height: 400px;
  overflow-y: auto;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

#style-chatbar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
}

#style-chatbar::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #f5f5f5;
}

#style-chatbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(36, 36, 36, 0.899);
  background-color: rgb(216 216 216 / 100%);
}

#style-msgBar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
}

#style-msgBar::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #f5f5f5;
}

#style-msgBar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(36, 36, 36, 0.899);
  background-color: rgb(216 216 216 / 100%);
}

.scrollbar {
  overflow-y: scroll;
}
.active {
  color: #54a3f5 !important;
}
.pleaseSelect {
  text-align: -webkit-center;
}
