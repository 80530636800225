.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

#style-checkoutbar::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #f5f5f5;
}

#style-checkoutbar::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-checkoutbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgb(206 206 206 / 30%);
  background-color: rgb(216 216 216 / 100%);
}

.scrollbar {
  overflow-y: scroll;
}
