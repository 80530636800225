.midwest-map {
  background-image: url('../../../public/img/map/midwest.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.west-map {
  background-image: url('../../../public/img/map/west.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.south-map {
  background-image: url('../../../public/img/map/south.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.north-map {
  background-image: url('../../../public/img/map/north.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.full-map {
  background-image: url('../../../public/img/map/full.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.text-shadow {
  text-shadow: 0 3px 2px rgb(0 0 0);
}
