.terms {
  display: block;
}

.terms > ol {
  list-style-type: none;
  counter-reset: item;
  margin: 10px;
  padding: 0;
}

.terms > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
  margin-left: 10px;
}

.terms > li::before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

.terms > li li::before {
  content: counters(item, '.') '';
}
