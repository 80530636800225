.confirm-background {
  background-image: url('../../../public/img/photographerBackground2.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: center;
  background-position-y: 2px;
}

.confirm-background-small {
  background-image: url('../../../public/img/photographerBackground2.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: center;
  background-position-y: -111px;
}

.container-confirm-brief {
  display: -webkit-flex; /* Safari */
  display: flex;
}

.image-confirm-brief {
  margin-left: -100px;
  -webkit-transition: margin-left 0.3s ease; /* Safari */
  transition: margin-left 0.3s ease;
  box-sizing: border-box;
}

.image-confirm-brief:first-of-type,
.container-confirm-brief:hover .image-confirm-brief:first-of-type {
  margin-left: 0;
}

.container-confirm-brief:hover .image-confirm-brief {
  margin-left: -30px;
  cursor: col-resize;
}

.image-confirm-brief:nth-child(1) {
  z-index: 90;
}
.image-confirm-brief:nth-child(2) {
  z-index: 80;
}
.image-confirm-brief:nth-child(3) {
  z-index: 70;
}
.image-confirm-brief:nth-child(4) {
  z-index: 60;
}
.image-confirm-brief:nth-child(5) {
  z-index: 50;
}
.image-confirm-brief:nth-child(6) {
  z-index: 40;
}
.image-confirm-brief:nth-child(7) {
  z-index: 30;
}
