.background-photographer-card-profile {
  background-image: url('../../../public/img/photographerBackgroundProfile.png');
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-device-width: 880px) {
  .background-photographer-card-profile {
    background-position: initial;
  }
}
