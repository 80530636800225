@media only screen and (min-device-width: 880px) and (max-device-width: 1005px) and (orientation: landscape) {
  .landscape-preview {
    height: 405px !important;
    width: 865px !important;
  }

  .landscape-preview-img {
    height: auto !important;
    max-width: 400px;
  }

  .landscape-preview-text {
    font-size: 15px;
  }

  .landscape-preview-text-hide {
    display: none;
  }
}
