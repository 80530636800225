.background-message {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAPBJREFUSEtj/P//fyIDA0M9EPMDMTXBO6BhZYxACx4BGdaMjIyPqWk60Fw9oHm9IAveAw0XpKbhILOA5ooDqXWjFuAM2dEgIpjoRoNoNIgIhgBBBaOpiKQgughUnQbEN6C6vgLrhz/QMp0ZSHMDMSNBEzEVmIPMBdUH1kBGIRCLALE8EOcCLdgCFGcFsgOAOBtUf5BhwUugni64y4AGglzbDcT7gRZsBvK9gewIIJ4I5J8hwwKwFqwWAMV/AXEKEE8AGn6UXMPRLQBZ1gXEoKBRBOImoOFnKTEcxQIQBxgs7kAqGIgXAfFRoAXkhD2KmwBA1LwZqI0+kAAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 11px;
  padding-bottom: 16px;
  background-position-y: center;
}

.print-brief {
  background-image: url('../../../public/img/print.png');
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-position-y: center;
}
