.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

#style-drop::-webkit-scrollbar-track {
  // margin-top: 20px;
  // margin-bottom: 20px;  
  border-radius: 70px;
  background-color: #e4e1e1;

}

#style-drop::-webkit-scrollbar {
  width: 5px;
  background-color: #e4e1e1;

}


#style-drop::-webkit-scrollbar-thumb {
  border-radius: 70px;
  box-shadow: inset 0 0 6px #54a3f5;
  background-color: #54a3f5;

}

.scrollbar {
  overflow-y: scroll;
  border-right: solid 10px transparent;
  border-top: solid 10px transparent;
  border-bottom: solid 10px transparent;
  background-color: white;
}
