.dashboard-background {
  background-image: url('../../../public/img/dashboardBackground.png');
  background-repeat: no-repeat;
  background-position-y: 110px;
  background-position-x: center;
  background-size: 220px;
}

.download-icon {
  background-image: url('../../../public/img/downloadWhite.png');
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 13%;
}
